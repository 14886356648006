import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element, ...rest }) => {
  const { isLoggedIn, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>; // Or use a spinner/loading indicator
  }
  //console.log("Logged in: " + isLoggedIn)
  return isLoggedIn ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
