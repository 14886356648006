// src/ApiContext.js
import React, { createContext, useContext } from 'react';

// Create a context for API configuration
const ApiContext = createContext();

// Create a provider component
export const ApiProvider = ({ children }) => {
  const apiBaseUrl = 'https://eezestdemo.narancs.xyz/api'; // Set your API base URL here

  return (
    <ApiContext.Provider value={{ apiBaseUrl }}>
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook to use the ApiContext
export const useApi = () => useContext(ApiContext);
