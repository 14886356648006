import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { useCommissionedDevices } from './CommissionedDeviceContext'; // Adjust the path as necessary


const commissioningSteps = [
    { message: 'Notifying protocol about the join', delay: 1000 },
    { message: 'Device joined to network', delay: 500 },
    { message: 'Requesting authentication data', delay: 2000 },
    { message: 'Authenticating', delay: 2000 },
    { message: 'Device joined', delay: 0 }, // No delay needed for the last step
  ];
  

function CommissionDevice() {
  const [deviceInfo, setDeviceInfo] = useState({
    name: '',
    protocol: '',
    // Add other fields as needed
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isStepInProgress, setIsStepInProgress] = useState(false);
  const [startCommissioning, setStartCommissioning] = useState(false);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setDeviceInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log(deviceInfo); // Here you would typically handle the commissioning logic
  };

  useEffect(() => {
    if (startCommissioning && currentStep < commissioningSteps.length) {
      setIsStepInProgress(true);
  
      const timer = setTimeout(() => {
        setIsStepInProgress(false);
        setCurrentStep(currentStep + 1);
  
        // Check if the commissioning process has just completed
        if (currentStep === commissioningSteps.length - 1) {
          // Commissioning process is done, add the device here
          handleDeviceCommissioned();
        }
  
      }, commissioningSteps[currentStep].delay);
  
      return () => clearTimeout(timer);
    }
  }, [currentStep, startCommissioning]);

  const { addDevice } = useCommissionedDevices();

  const handleDeviceCommissioned = () => {
    const newDevice = {
        id: generateRandomHex(12), // Generate a 12-character HEX ID
        networkAddress: generateRandomHex(16), // Generate a 16-character network address
        authMethod: getRandomAuthMethod(), // Randomly select an auth method
        name: deviceInfo.name,
        protocol: deviceInfo.protocol,
        // Include any other relevant device information
      };

    addDevice(newDevice); // Add the new device to the global list
  };

  function generateRandomHex(len) {
    let output = '';
    for (let i = 0; i < len; i++) {
      output += Math.floor(Math.random() * 16).toString(16); // Generates a random hex digit and appends it
    }
    return output;
  }
  
  function getRandomAuthMethod() {
    const methods = ["token", "certificate", "no auth"];
    return methods[Math.floor(Math.random() * methods.length)]; // Randomly selects an auth method
  }
  

  return (
    <Box className="w-1/2">
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',}} className=" mx-auto p-6 px-12 w-auto border shadow-lg rounded-lg" style={{ maxWidth: '500px' }}>
            <Typography variant="h6">Connect New Device</Typography>
            <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Device Name"
                name="name"
                value={deviceInfo.name}
                onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
                <InputLabel id="protocol-label">Protocol</InputLabel>
                <Select
                labelId="protocol-label"
                id="protocol"
                value={deviceInfo.protocol}
                label="Protocol"
                name="protocol"
                onChange={handleChange}
                >
                <MenuItem value="WiFi">WiFi</MenuItem>
                <MenuItem value="LoRa">LoRa</MenuItem>
                <MenuItem value="BLE">BLE</MenuItem>
                <MenuItem value="OpenThread">OpenThread</MenuItem>
                {/* Add other protocols as needed */}
                </Select>
            </FormControl>
            {/* Add other input fields as needed */}
            <Button
                variant="contained"
                onClick={() => {
                    setStartCommissioning(true); // Start the commissioning process
                    setCurrentStep(0); // Reset to the first step
                }}
                disabled={isStepInProgress || currentStep >= commissioningSteps.length}
                >
                Connect
            </Button>
        </Box>
        <Box className="w-full mx-auto py-8">
            {startCommissioning && commissioningSteps.slice(0, currentStep + 1).map((step, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }} className="mx-auto">
                {/* Container for text and icon to keep them closer together */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    <Typography sx={{
                    color: index < currentStep ? 'grey' : 'black', // Done steps are grey, the current step is black
                    fontSize: index < currentStep ? '0.8em' : '1em', // Done steps are smaller
                    textAlign: 'center'
                    }}>
                    {step.message}
                    </Typography>
                    {index === currentStep ? (
                    isStepInProgress ? <CircularProgress size={20} /> : (currentStep === commissioningSteps.length - 1 ? <Check sx={{ color: 'green' }} /> : null)
                    ) : (
                    currentStep > index ? <Check sx={{ color: 'green' }} /> : null
                    )}
                </Box>
                </Box>
            ))}
        </Box>


    </Box>
  );
}

export default CommissionDevice;
