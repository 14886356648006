import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import CommissionDevice from './CommissionDevice';
import { CommissionedDevicesProvider } from './CommissionedDeviceContext';
import { useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute'
import { AuthProvider } from './AuthContext';

function App() {
  return (
    <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard/*" element={
                <ProtectedRoute element={
                    <CommissionedDevicesProvider>
                        <Dashboard />
                    </CommissionedDevicesProvider>
                  } />
                } />
          </Routes>
        </Router>
    </AuthProvider>
  );
}

export default App;
