import React, { useState } from 'react';
import { Button, TextField, Typography, Container, styled } from '@mui/material';
import logoImage from './assets/eezest-logo.png';
import { useApiInstance } from './api';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';


const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10),
  }));
  
  const StyledForm = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue
    marginTop: theme.spacing(1),
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
  }));
  

  function Login({ setIsLoggedIn }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); // To handle error messages
    const api = useApiInstance();
    const { login } = useAuth();
    const navigate = useNavigate();
  
    const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Make a POST request to the login endpoint
      const response = await api.post('/login', { username, password });

      // Assuming the token is returned in response.data.token
      const token = response.data.access_token;
      //console.log(response)

      // Store the token in localStorage or a state management solution
      localStorage.setItem('token', token);

      // Update the authentication state
      //setIsLoggedIn(true);
      login();
      navigate('/dashboard');

      // Clear any previous error message
      setError('');
    } catch (err) {
      // Handle error (e.g., incorrect credentials)
      setError('Invalid username or password');
      //setIsLoggedIn(false);
    }
  };
  
    return (
      <StyledContainer maxWidth="xs">
        <img src={logoImage} alt="Logo" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <StyledForm noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography align="center" color="error">{error}</Typography>}
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
          >
            Sign In
          </StyledButton>
        </StyledForm>
      </StyledContainer>
    );
  }
  

export default Login;
