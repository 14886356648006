import React, { createContext, useState, useContext } from 'react';

const CommissionedDevicesContext = createContext();

export const useCommissionedDevices = () => useContext(CommissionedDevicesContext);

export const CommissionedDevicesProvider = ({ children }) => {
  const [devices, setDevices] = useState([]);

  const addDevice = (device) => {
    setDevices((prevDevices) => [...prevDevices, device]);
  };

  return (
    <CommissionedDevicesContext.Provider value={{ devices, addDevice }}>
      {children}
    </CommissionedDevicesContext.Provider>
  );
};
