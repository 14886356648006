import React, { useState } from 'react';
import { Box, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useCommissionedDevices } from './CommissionedDeviceContext'; // Adjust the path as necessary

function ConnectedDevices() {
  const { devices } = useCommissionedDevices();
  const [selectedTab, setSelectedTab] = useState(0);

  // Extract unique protocols from devices
  //const protocols = [...new Set(devices.map(device => device.protocol))];
  const protocols = [...new Set(["WiFi", "LoRa", "BLE", "OpenThread"])]

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Filter devices based on the selected tab
  const filteredDevices = selectedTab === 0 ? devices : devices.filter(device => device.protocol === protocols[selectedTab - 1]);

  const protocolData = {
    All: {
      "Gateway ID": "0x113245"
    },
    WiFi: {
      "SSID": "eezest_113245",
      "Security": "WPA3",
      "Status": "Operational",
      "Frequency": "2.4GHz",
      "Band": "25",
      "Transmit Power": "15 dBm",
      // Other WiFi-specific key-value pairs...
    },
    LoRa: {
      "Network ID": "LoRa-789012",
      "Frequency Band": "868 MHz",
      "Data rate plan": "125KHz@SF7",
      "Spreading Factor": "SF7",
      "Transmit Power": "14 dBm",
      "Status": "Issues",
      "Security": "AES-128",
      // Other LoRa-specific key-value pairs...
    },
    BLE: {
      "Network ID": "BLE-345678",
      "Status": "Operational",
      "Version": "5.0",
      "Transmit Power": "-20dBm",
      "Data Rate": "2 MBps",
      "Encryption": "AES-CCM",
      "PHY": "LE 2M (2 Mbps)"
      // Other BLE-specific key-value pairs...
    },
    OpenThread: {
        "Network PAN ID": "0xF45C",
        "Network MAC ID": "0x18CF8472BA52341C",
        "Status": "Operational",
        "Transmit Power": "-18 dBm",
        "Device Type": "Border Router",
        "Version": "Thread 1.1"
    }
    // Add more protocols as necessary
  };

  const renderProtocolData = (protocol) => {
    let data = { ...protocolData[protocol] }; // Create a copy to avoid mutating the original data
  
    if (protocol === 'All') {
      // Dynamically set the total number of devices
      data["Total Devices"] = `${devices.length}`;
    }
  
    return (
      <Box sx={{ height: '200px', overflow: 'auto', marginBottom: 2 }} className="my-5">
        {Object.entries(data).map(([key, value], index) => (
          <Typography key={index}>
            <span><strong>{key}:</strong></span> <span>{value}</span>
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
    
      <Tabs value={selectedTab} onChange={handleChange} aria-label="device tabs">
        <Tab label="All Devices" />
        {protocols.map((protocol, index) => (
          <Tab key={index} label={protocol} />
        ))}
      </Tabs>

      {renderProtocolData(selectedTab === 0 ? 'All' : protocols[selectedTab - 1])}
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="shadow-xl rounded-sm">
          <TableHead>
            <TableRow className="bg-gray-100/50 border-b-1 border-black">
              <TableCell>Device Name</TableCell>
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">Protocol</TableCell>
              <TableCell align="right">Network Address</TableCell>
              <TableCell align="right">Auth Method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDevices.map((device, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="hover:bg-gray-100/70"
              >
                <TableCell component="th" scope="row">
                  {device.name}
                </TableCell>
                <TableCell align="right">{device.id}</TableCell>
                <TableCell align="right">{device.protocol}</TableCell>
                <TableCell align="right">{device.networkAddress}</TableCell>
                <TableCell align="right">{device.authMethod}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ConnectedDevices;
