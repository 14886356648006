import React from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CloudIcon from '@mui/icons-material/Cloud';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import DeviceHubIcon from '@mui/icons-material/DeviceHub'; // You can choose appropriate icons for your sections
import StatusIndicator from './StatusIndicator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import CommissionDevice from './CommissionDevice';
import Home from './Home';
import ConnectedDevices from './ConnectedDevices';
import { useAuth } from './AuthContext';

const drawerWidth = 240;  


function Dashboard() {
    // Your existing Dashboard component code...
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout(); // Call logout function to update context
        localStorage.removeItem('token'); // Remove token from localStorage
        navigate('/'); // Redirect to login page
      };
  
    return (
      <Box sx={{ display: 'flex' }}>
        {
            <Box sx={{ display: 'flex', width: '100%'}}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <Toolbar className="bg-blue-900">
                <Typography variant="h6" noWrap>
                  EEZEST IoT Dashboard
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}
            >
              <Toolbar /> {/* This Toolbar component is just to offset the content below the AppBar */}
              <Box sx={{ overflow: 'auto', height: '100%' }} className="bg-white">
                <List>
                  {/* Home Link */}
                    <ListItem button component={Link} to="/dashboard">
                        <ListItemIcon>
                        <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>

                    {/* Commission Device Link */}
                    <ListItem button component={Link} to="/dashboard/commission-device">
                        <ListItemIcon>
                        <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add Device" />
                    </ListItem>

                    <ListItem button component={Link} to="/dashboard/connected-devices">
                        <ListItemIcon>
                        <CloudIcon />
                        </ListItemIcon>
                        <ListItemText primary="Devices" />
                    </ListItem>

                    {/*
                    <ListItem button component={Link} to="/dashboard/settings">
                        <ListItemIcon>
                        <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                    */}

                    {/*
                    <ListItem button component={Link} to="/dashboard/account">
                        <ListItemIcon>
                        <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Account" />
                    </ListItem>
                    */}
                </List>
                  <Box marginTop="auto" sx={{ flexGrow: 1 }} />
                      {/* Logout Button */}
                      <List>
                        <ListItem button onClick={handleLogout}>
                          <ListItemIcon>
                            <PersonIcon /> {/* Use an appropriate icon for logout */}
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </List>
              </Box>
            </Drawer>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}
            >
              <Toolbar /> {/* This Toolbar component is also to offset the content below the AppBar */}
              {/* Main content will go here */}
                      {/* Dashboard Internal Routing */}
                <Routes>
                <Route path="/" element={<Home />} /> {/* Default Dashboard View */}
                <Route path="/commission-device" element={<CommissionDevice />} /> {/* Commission Device Form */}
                <Route path="/connected-devices" element={<ConnectedDevices />} /> {/* Commission Device Form */}
                {/* Add more routes as needed */}
                </Routes>
            </Box>
          </Box>
        }
  
        <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Toolbar /> {/* Keep this Toolbar to offset the content below the AppBar */}
        </Box>

      </Box>
    );
  }

export default Dashboard;
