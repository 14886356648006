import React from 'react';

const StatusIndicator = ({ status }) => {
    const getColor = (status) => {
      switch (status) {
        case 'Operational':
          return 'green';
        case 'Issues':
          return 'yellow';
        default:
          return 'red';
      }
    };
  
    return (
      <span style={{ height: '15px', width: '15px', backgroundColor: getColor(status), borderRadius: '50%', display: 'inline-block', marginRight: '10px' }} />
    );
  };
  

export default StatusIndicator;
