import React from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import StatusIndicator from './StatusIndicator';
import { useCommissionedDevices } from './CommissionedDeviceContext';

const cardStyle = () => (
    "bg-white shadow-md hover:shadow-lg rounded-lg overflow-hidden m-4 p-6 transition-shadow duration-200 ease-in-out w-80"
  );

const serviceHealthStatuses = [
  { name: 'Network', status: 'Operational' },
  { name: 'Eezest Cloud', status: 'Operational' },
  { name: 'Authentication Server', status: 'Operational' },
  { name: 'Gateway', status: 'Operational' },
  { name: 'Third party cloud service', status: 'Issues' },
];

function Home() {

    const { devices } = useCommissionedDevices();

    return (
        <Box>
            <Card className={cardStyle()}>
        <CardContent>
        <Typography variant="h6">Connected Devices</Typography>
        <Typography variant="h4">{devices.length}</Typography>
        </CardContent>
        </Card>

        <Card className={cardStyle()}>
        <CardContent>
        <Typography variant="h6">Health Status</Typography>
        <List>
            {serviceHealthStatuses.map((service, index) => (
            <ListItem key={index}>
                <ListItemIcon>
                <StatusIndicator status={service.status} />
                </ListItemIcon>
                <ListItemText primary={service.name} />
            </ListItem>
            ))}
        </List>
        </CardContent>
        </Card>
        </Box>
    );
}

export default Home;